import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 60px 0;

  iframe {
    height: 600px;
    width: 90%;
    max-width: 1000px;

    @media only screen and (max-width: 850px) {
      height: 400px;
    }

    @media only screen and (max-width: 600px) {
      height: 350px;
    }
  }

  h1 {
    color: white;
  }
`

const VideoPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`dapper`, `conspiracy`, `the dapper conspiracy`]}
    />

    <iframe
      src="https://www.youtube.com/embed/46CIwUDffjI?rel=0"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />

    <iframe
      src="https://www.youtube.com/embed/0nMBj8-Ii84?rel=0"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />

    <iframe
      src="https://www.youtube.com/embed/ANEPwUyQKCc?rel=0"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Layout>
)

export default VideoPage
